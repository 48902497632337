
import { defineComponent, ref } from 'vue'
import { Form as Validation } from 'vee-validate'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import SignupPassword from '@/components/shared/SignupPassword.vue'

export default defineComponent({
  components: {
    TmFormLine,
    TmModal,
    TmButton,
    Validation,
    SignupPassword,
  },
  setup() {
    const password = ref({
      current: '',
      new: '',
      newRepeat: '',
    })

    return {
      password,
    }
  },
})
